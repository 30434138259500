import app from "./init";

import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  signOut,
} from "firebase/auth";

const auth = getAuth(app);

function logout() {
  return new Promise((reject, resolve) => {
    signOut(auth)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function googleSignIn() {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        resolve({ user, token });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function facebookSignIn() {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, new FacebookAuthProvider())
      .then((result) => {
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        resolve({ user, token });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  googleSignIn,
  facebookSignIn,
  logout,
};
